import React, { useLayoutEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import { MdStar } from "react-icons/md"
import Helmet from "react-helmet"

import Layout from "./layout"
import colors from "./colors"
import Newsletter from "./Newsletter"
import ContactBanner from "./ContactBanner"
import CalendlyButton from "../components/CalendlyButton"
//import Workshop from "../components/workshop"

import ReactMarkdown from "react-markdown/with-html"

// data fields to add: workshop visual, learning goals 1,2,3, testimonial text, testimonial subline, Host tags

const useWindowSize = refContainer => {
  const [size, setSize] = useState([0])
  useLayoutEffect(() => {
    const updateSize = () => {
      setSize(refContainer.current.offsetWidth)
    }
    if (typeof window !== "undefined")
      window.addEventListener("resize", updateSize)
    updateSize()
    return () =>
      typeof window !== "undefined"
        ? window.removeEventListener("resize", updateSize)
        : null
  })
  return size
}

const WorkshopDetail = props => {
  const { item, webinar } = props.pageContext
  const itemToUse = props.store.language === 'en'? item.en : item.de
  const en = props.store.language==='en'
  const styles = props.store.device === 'desktop' ? desktopStyles:mobileStyles
  const refContainer = React.useRef(null)
  const containerWidth = useWindowSize(refContainer)
  
  return (
    <Layout>
    <Helmet>
          <title>{'LT | ' + itemToUse.title}</title>
    </Helmet>
    <img
      style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
      src={itemToUse.Visual}
      alt="Fans cheering"
    />
      <div style={styles.pageContainer}>
        <div style={styles.contentContainer}>
          <div style={styles.breadCrumbSection}>
            <Link to={"/"} style={styles.link}>
              Home
            </Link>{" "}
            /{" "}
            <Link to={"/insights"} style={styles.link}>
              {" "}
              Insights
            </Link>{" "}
            / {itemToUse.title}
          </div>
          <h2 style={styles.header}>{itemToUse.title}</h2>
          <div style={styles.infoSection}>
            <div style={styles.infoContainer}>
            <ReactMarkdown
                  source={itemToUse.briefDescription}
                  escapeHtml={false}
                  parserOptions={{ commonmark: true }}
                  style={{marginBottom:0}}
                />
              <div style={styles.tagSection}>
                {[itemToUse.tag1, itemToUse.tag2, itemToUse.tag3].map(tag => (
                  <div style={styles.tagContainer}>
                    <p style={{ margin: 0 }}>{tag}</p>
                  </div>
                ))}
              </div>
            </div>
            {webinar && 
            <WebinarContactSection
              title={itemToUse.title}
              duration={itemToUse.duration}
              date={itemToUse.date}
              cost={itemToUse.cost}
              calendlyLink={itemToUse.calendlyLink}
            />}
            {!webinar && 
            <WorkshopContactSection
              title={itemToUse.title}
              duration={itemToUse.duration}
              date={itemToUse.date}
              cost={itemToUse.cost}
            />}
          </div>
        </div>
      </div>
      <div style={styles.lowerSection}>
        <div style={styles.lowerSectionContainer}>
          <div>
            <div>
              <table style={{}}>
                <tr>
                  <td style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <strong>{en?'For you:':'Zielgruppe:'}</strong>
                  </td>
                  <td>
                  <ReactMarkdown
                  parserOptions={{ commonmark: true }}
                  source={itemToUse.targetGroup}
                  escapeHtml={false}
                />
                  
                  </td>
                </tr>
                <tr>
                  <td style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <strong>Location</strong>
                  </td>
                  <td>{itemToUse.location}</td>
                </tr>
              </table>
            </div>
            <div style={styles.descriptionContainer}>
            <ReactMarkdown
                  source={itemToUse.description}
                  escapeHtml={false}
                  parserOptions={{ commonmark: true }}
                />
            </div>
            <div>
              <h2 style={styles.header}> {en?'What you will learn':'Was Sie erwartet'}</h2>
              <div ref={refContainer} style={styles.goalsContainer}>
                <LearningGoal
                  width={containerWidth}
                  text={itemToUse.learningGoal1}
                />
                <LearningGoal
                  width={containerWidth}
                  text={itemToUse.learningGoal2}
                />
                <LearningGoal
                  width={containerWidth}
                  text={itemToUse.learningGoal3}
                />
              </div>
            </div>
            {itemToUse.hosts && itemToUse.hosts.length !== 0 && <div>
              <h2 style={styles.hostSectionHeader}>Your Hosts</h2>

              {itemToUse.hosts.map(host => (
                <Host
                  image={host.hostImage}
                  name={host.hostName}
                  description={host.hostDescription}
                  tags={host.hostSubline}
                />
              ))}
            </div>}
            {itemToUse.testimonial && itemToUse.testimonialSubline &&
            <div style={styles.testimonial}>
              <div style={{}}>
                {["", "", "", "", ""].map(() => (
                  <MdStar style={styles.star} />
                ))}
              </div>
              <div style={styles.testimonialContainer}>
                <ReactMarkdown
                  source={"<center>" + itemToUse.testimonial + "</center>"}
                  escapeHtml={false}
                  parserOptions={{ commonmark: true }}
                />
              </div>
              <ReactMarkdown
                source={"<center>" + itemToUse.testimonialSubline + "</center>"}
                escapeHtml={false}
                parserOptions={{ commonmark: true }}
              />
            </div>}
            
            
            <div>
            {!webinar && <WorkshopCTA en={en} styles={styles} title={itemToUse.title}/>}
            {webinar && <CalendlyButton styles={styles.lowerButton} url={itemToUse.calendlyLink}>{en?'Register':'Anmelden'} </CalendlyButton>}
            
              
            </div>
          </div>
        </div>
        
      </div>
      <div style={{ backgroundColor: colors.lightgrey, position:'relative', zIndex: 1 }}>
      <ContactBanner backgroundColor={colors.greyBackground} />
      </div>
      <Newsletter />
    </Layout>
  )
}

export default inject("store")(observer(WorkshopDetail))

const LearningGoal = inject('store')(observer((props) => {
  const { width} = props
  return (
    <div
      style={{
        padding: "25px",
        margin: "0px 10px 40px 0px",
        width: width / 3.5,
        height: width / 3.5,
        minWidth: 300,
        minHeight: 300,
        backgroundColor: "white",
        clipPath: "polygon(0% 100%, 85% 100%,100% 85% ,100% 0%, 0% 0%)",
        WebkitClipPath: "polygon(0% 100%, 85% 100%,100% 85% ,100% 0%, 0% 0%)",
      }}
    >
      <ReactMarkdown source={props.text} escapeHtml={false} parserOptions={{ commonmark: true }}/>
    </div>
  )
}))

const Host = inject('store')(observer((props) => {
  const styles = props.store.device === 'desktop' ? desktopStyles: mobileStyles
  return (
    <div style={styles.hostContainer}>
      <img src={props.image} style={styles.hostImage} alt={props.name} />
      <div style={styles.hostTextContainer}>
        <h2 style={styles.hostHeader}>{props.name}</h2>
        <p style={styles.hostTags}>{props.tags}</p>
        <p style={styles.hostDescription}>{props.description}</p>
      </div>
    </div>
  )
}))

const WebinarContactSection = inject('store')(observer((props) => {
  const en = props.store.language === 'en'
  const {date, duration, cost, calendlyLink} = props
  const styles = props.store.device === 'desktop' ? desktopStyles:mobileStyles
  return(
  <div style={styles.upperSection}>
    <table style={{ width: "100%" }}>
      <tr>
        <td style={{ display: 'flex', alignItems: 'flex-start' }}>
          <strong>{en?'Date:' : 'Datum:'}</strong>{" "}
        </td>
        <td>
        <ReactMarkdown parserOptions={{ commonmark: true }} source={date} escapeHtml={false} style={{marginBottom:0}}/>

        </td>
      </tr>
      <tr>
        <td>
          <strong>{en?'Duration:':'Dauer:'}</strong>
        </td>
        <td>{duration}</td>
      </tr>
      <tr>
        <td>
          <strong>{en?'Cost:': 'Gebühr:'}</strong>
        </td>
        <td>{cost}</td>
      </tr>
    </table>

      <CalendlyButton styles={styles.button} url={calendlyLink}>
      <p style={styles.buttonText}>{en?'Register':'Anmelden'}</p>
      </CalendlyButton>
    
  </div>
  )}
  ))

  const WorkshopContactSection = inject('store')(observer((props) => {
    const {date, title} = props
    const en = props.store.language==='en'

    const deMailto = `mailto:hello@liquidi.team?subject=Anfrage%20zu%20Workshop%20”${title}”&body=Guten Tag Liquiditeam,%0D%0A%0D%0A
    Mein Name ist  _______ von dem Unternehmen _______.%0D%0A
    Ich habe Interesse an ihrem Workshop "${title}” und würde gerne weitere Informationen dazu erhalten.%0D%0A%0D%0A
    Bitte rufen sie mich unter der Nummer  _______ für ein Erstgespräch an. ODER%0D%0A
    Bitte schicken Sie mir im ersten Schritt weitere Informationen per Email.%0D%0A%0D%0A
    Mit freundlichen Grüßen
    `
    const enMailto = `mailto:hello@liquidi.team?subject=Request%20regarding%20Workshop%20”${title}&body=Hello Liquiditeam,%0D%0A%0D%0A
    my name is  _______ from the company _______.%0D%0A
    I am interested in your workshop “${title}” and would like more information.%0D%0A%0D%0A
    Please call me at  _______ for an initial meeting. OR%0D%0A
    In the first step, please send me further information by email.%0D%0A%0D%0A
    Yours sincerely`
    const mailto = en? enMailto:deMailto
    const styles = props.store.device === 'desktop' ? desktopStyles:mobileStyles
    return(
    <div style={styles.upperSection}>
      <table style={{ width: "100%" }}>
        <tr>
          <td style={{ display: 'flex', alignItems: 'flex-start' }}>
            <strong>Date:</strong>{" "}
          </td>
          <td>{date}</td>
        </tr>
        
      </table>

    <div style={styles.button} onClick={() => (window?window.location.href = mailto:null)}>
        <p style={styles.buttonText}>{en?'Request Information':'Mehr Informationen'}</p>
      </div>
    </div>
    )}
  ))

const WorkshopCTA = ({en, styles, title}) => {
  const deMailto = `mailto:hello@liquidi.team?subject=Anfrage%20zu%20Workshop%20”${title}”&body=Guten Tag Liquiditeam,%0D%0A%0D%0A
    Mein Name ist  _______ von dem Unternehmen _______.%0D%0A
    Ich habe Interesse an ihrem Workshop "${title}” und würde gerne weitere Informationen dazu erhalten.%0D%0A%0D%0A
    Bitte rufen sie mich unter der Nummer  _______ für ein Erstgespräch an. ODER%0D%0A
    Bitte schicken Sie mir im ersten Schritt weitere Informationen per Email.%0D%0A%0D%0A
    Mit freundlichen Grüßen
    `
    const enMailto = `mailto:hello@liquidi.team?subject=Request%20regarding%20Workshop%20”${title}&body=Hello Liquiditeam,%0D%0A%0D%0A
    my name is  _______ from the company _______.%0D%0A
    I am interested in your workshop “${title}” and would like more information.%0D%0A%0D%0A
    Please call me at  _______ for an initial meeting. OR%0D%0A
    In the first step, please send me further information by email.%0D%0A%0D%0A
    Yours sincerely`
    const mailto = en? enMailto:deMailto
  return(
  <div style={styles.lowerButton} onClick={() => (window?window.location.href = mailto:null)}>
                <p style={styles.lowerButtonText}>{en?'Request Information':'Mehr Informationen'}</p>
              </div>
)}
const WebinarCTA = ({en, styles}) => (
  
  <div style={styles.lowerButton} onClick={() => {
    if (window){
    en?
    window.open('https://forms.gle/z8V9ZtB42vjQCHcGA', '_blank')
    :
    window.open('https://forms.gle/CuoQotjtBmf4u6ZV9', '_blank')
    }}}>
                <p style={styles.lowerButtonText}>{en?'Register':'Anmelden'}</p>
              </div>
)

const desktopStyles = {
  pageContainer: { backgroundColor: "white", marginTop:'-10px' },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  infoSection: {
    display: "flex",
    flexDirection: "row",
    //alignItems: "flex-end",
  },
  infoContainer: {
    flex: 2.5,
    paddingRight: "50px",
    paddingTop: '5px',
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  tagSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagContainer: {
    margin: "10px 10px 10px 0px",
    padding: "10px",
    flex: 1,
    maxWidth: "500px",
    minWidth: "200px",
    backgroundColor: colors.lightgrey,
  },
  upperSection: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px 0px",
    padding: "20px",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
  },
  buttonText: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    color: colors.white,
  },
  lowerSection: { backgroundColor: colors.greyBackground },
  lowerSectionContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: "black",
    padding: "50px 0px",
  },
  descriptionContainer: { maxWidth: "1200px", marginBottom: '1.45rem' },
  goalsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  hostSectionHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    margin: "25px 0px 10px",
  },
  testimonial: {
    margin: "50px 0px",
    width: "100%",
    backgroundColor: colors.darkblue,
    color: "white",
    display: "flex",
    padding: "35px 10% 35px 10%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  star: {
    margin: "0px 15px 15px",
    height: "30px",
    width: "35px",
    color: colors.gold,
  },
  testimonialContainer: { marginBottom: "35px" },
  lowerButton: {
    margin: "auto",
    maxWidth: "350px",
    width: "100%",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "35",
    cursor: "pointer",
  },
  lowerButtonText: { fontSize: "18px", fontWeight: "bold", margin: 0 },
  hostContainer: { display: "flex", flexDirection: "row", padding: "25px 0px" },
  hostImage: { maxHeight: "225px", maxWidth: "225px", objectFit: "cover" },
  hostTextContainer: { margin: "0px 25px" },
  hostHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  hostTags: { color: colors.teamTags },
  hostDescription: { color: "#4F4F4F" },
}

const mobileStyles = {
  pageContainer: { backgroundColor: "white", marginTop:'-10px' },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbSection: { paddingBottom: "50px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  infoSection: {
    display: "flex",
    flexDirection: "column",
    //alignItems: "flex-end",
    
  },
  infoContainer: {
    padding:'20px 0px'
  },
  tagSection: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  tagContainer: {
    margin: "10px 10px 10px 0px",
    padding: "10px",
    flex: 1,
    maxWidth: "500px",
    minWidth: "300px",
    backgroundColor: colors.lightgrey,
  },
  upperSection: {
    flex: 1,
    flexDirection: "column",
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    margin: "10px 0px",
    padding: "20px",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    fontSize: "18px",
    fontWeight: "bold",
    margin: 0,
    color: colors.white,
  },
  lowerSection: { backgroundColor: colors.greyBackground },
  lowerSectionContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: "black",
    padding: "50px 0px",
  },
  descriptionContainer: { maxWidth: "1200px", marginBottom: '1.45rem' },
  goalsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  hostSectionHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    margin: "25px 0px 10px",
  },
  testimonial: {
    margin: "50px 0px",
    width: "100%",
    backgroundColor: colors.darkblue,
    color: "white",
    display: "flex",
    padding: "35px 10% 35px 10%",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  star: {
    margin: "0px 5px 5px",
    height: "30px",
    width: "35px",
    color: colors.gold,
  },
  testimonialContainer: { marginBottom: "35px" },
  lowerButton: {
    margin: "auto",
    maxWidth: "350px",
    width: "100%",
    height: "55px",
    backgroundColor: colors.gold,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
    fontSize: "35",
  },
  lowerButtonText: { fontSize: "18px", fontWeight: "bold", margin: 0 },
  hostContainer: { padding: "25px 0px" },
  hostImage: { maxHeight: "225px", maxWidth: "225px", objectFit: "cover" },
  hostTextContainer: { margin: "10px 0px" },
  hostHeader: {
    fontSize: "1.5625rem",
    color: colors.black,
    marginBottom: "10px",
  },
  hostTags: { color: colors.teamTags },
  hostDescription: { color: "#4F4F4F" },
}
